<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="수질오염물질 측정내용"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" /> -->
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-report-10',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envWaterDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      envWaterMstInspectItemIds: [],
      grid: {
        columns: [
          {
            name: 'envWaterMstMeasurelocName',
            field: 'envWaterMstMeasurelocName',
            label: '측정위치 ',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
            fix: true,
          },
        ],
        height: '600px',
        data: [],
      },
      listUrl: '',
      headerUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.self.measureloc.list.url;
      this.headerUrl = selectConfig.env.water.self.measureloc.header.url;
      this.getHeader();
    },
    getHeader() { 
      this.$http.url = this.headerUrl;
      this.$http.param = {plantCd: this.param.plantCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.envWaterMstInspectItemIds = [];
        this.$_.forEach(_result.data, item => {
          this.envWaterMstInspectItemIds.push(item.envWaterMstInspectItemId);
          this.grid.columns.push(
            {
              name: item.envWaterMstInspectItemId,
              field: item.envWaterMstInspectItemId,
              label: item.envWaterMstInspectItemName + '<br>',
              align: 'center',
              // type: 'number',
              style: 'width: 90px',
              sortable: false,
              colorClass: ['R,bg-red-6 text-white','I,bg-orange-4 text-white'],
              colorTarget: item.envWaterMstInspectItemId + '001',
              helpcomment: '법적기준: ' + item.legalStandard + '<br>내부기준: ' + item.innerStandard,
            }
          )
        });
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.param = {measureDt: this.param.dailyResultDt, plantCd: this.param.plantCd, envWaterMstInspectItemIds: this.envWaterMstInspectItemIds}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
